import { Component,OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-ecommerce.pages',
  templateUrl: './ecommerce.pages.component.html',
  styleUrls: ['./ecommerce.pages.component.scss']
})
export class EcommercePagesComponent implements OnInit {
  name = 'Kissht';
  KisshtHtml;
  constructor(private http:HttpClient,
  private sanitizer:DomSanitizer){

  }
  ngOnInit(){
    this.http.get('https://www.toolway.com/en',{responseType:'text'}).subscribe(res=>{
      this.KisshtHtml = this.sanitizer.bypassSecurityTrustHtml(res);
      console.log(this.KisshtHtml)
    })
  }
}

