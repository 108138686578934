import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-portfolio-page',
    templateUrl: './portfolio-page.component.html',
    styleUrls: ['./portfolio-page.component.scss']
})
export class PortfolioPageComponent implements OnInit {
    titleHeader:string;
    constructor() {
        titleHeader:'madhav'
     }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Portfolio'
        }
    ]

    aboutImage: Image[] = [
        {
            img: 'assets/img/comingSoon1024x1024.jpg'
        }
    ]

}
class pageTitle {
    title : string;
}

class Image {
    img : string;
}