<section class="page-title-area">
    <div class="container">

        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">

            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
        <!-- <div>
            <div class="about-image" *ngFor="let Image of aboutImage;">
                <img [src]="Image.img" alt="image">
            </div>
        </div> -->
        <section class="statistic-section text-section ff-section-fullwidth  clearfix section--heading" data-parallax="scroll" style="padding-bottom:25px;">
            <div class="section-background-block parallax background-image" style="background-image:url('images/blue.jpg');"></div>
            <div class="inner-container container">
                <div class="row">
                    <div id="sp-portfolio-module-119" class="sp-portfolio default">
                        <ul class="sp-portfolio-filter">
                            <li><a class="btn active" href="#" data-filter="*">Show All</a></li>
                            <li><a class="btn" routerLink="../ecommerce" data-filter=".website">Ecommerce</a></li>
                            <li> <a class="btn" href="#" data-filter=".service">Digital Marketing</a></li>
                            <li><a class="btn" href="#" data-filter=".application">Application</a></li>
                            <li><a class="btn" href="#" data-filter=".training">Training </a></li>
                        </ul>
                      
                    </div>
                    <!--/.sp-portfolio-->
                </div>
               </div>
       </section>
    </div>


</section>