<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>{{InfoBox1.title}}</h3>
                    <p>{{InfoBox1.location}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>{{InfoBox2.title}}</h3>
                    <p>Mobile: <a href="tel:{{InfoBox2.number}}">{{InfoBox2.number}}</a></p>
                    <p>E-mail: <a href="mailto:{{InfoBox2.email}}">{{InfoBox2.email}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box" *ngFor="let InfoBox3 of contactInfoBox3;">
                    <div class="back-icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <h3>{{InfoBox3.title}}</h3>
                    <p>{{InfoBox3.text1}}</p>
                    <p>{{InfoBox3.text2}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image" *ngFor="let Image of contactImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" class="form-control" id="name" placeholder="Your name">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" class="form-control" id="email" placeholder="Your email address">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" class="form-control" id="phone_number" placeholder="Your phone number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" id="message" class="form-control" cols="30" rows="6" placeholder="Write your message..."></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Send Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
</div>