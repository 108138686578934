import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Case study #1',
            title: 'We help business meet their full potential.',
            paragraphText1: 'Meritotech will partnership with you to align your corporate goal through a proposition.',
            paragraphText2: 'We believe that understanding each client needs is essential to provide quality service.',
            // link: '#ase-studies-details',
            link: '/',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/sld.jpg'
        },
        {
            subTitle: 'Case study #2',
            title: 'We are your digital transformation partner',
            paragraphText1: 'Meritotech is your digital transformation partner towards your innovation journey',
            paragraphText2: 'Our Business Strategy is to offer “Simplified Solutions” to customers to grow their business to the next level.',
            // link: 'case-studies-details',
            link: '/',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/Meritotech-Slider-2.jpg'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}